import global from './global.js';
import datatable from './datatable';
import dataview from './dataview';
import paginator from './paginator';
import speeddial from './speeddial';
import splitbutton from './splitbutton';
import button from './button';
import toolbar from './toolbar';
import togglebutton from './togglebutton';
import multiselect from './multiselect';
import skeleton from './skeleton';
import progressbar from './progressbar';
import steps from './steps';
import accordion from './accordion';
import inputotp from './inputotp';
import selectbutton from './selectbutton';
export default {
    global,
    datatable,
    dataview,
    paginator,
    speeddial,
    splitbutton,
    button,
    toolbar,
    togglebutton,
    multiselect,
    skeleton,
    progressbar,
    steps,
    accordion,
    inputotp,
    selectbutton
}
