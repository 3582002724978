<template>
  <div class="loader-backdrop" v-if="showLoader">
    <div class="loader">Loading...</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showLoader: false,
    };
  },
};
</script>

<style>
.loader-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader {
  color: #fff;
  font-size: 1.5em;
}
</style>
