// backdropPlugin.js
const backdropPlugin = {
    install(app) {
      app.config.globalProperties.$showBackdrop = function(text) {
        // Criar o backdrop
        const backdrop = document.createElement('div');
        backdrop.className = 'backdrop';
  
        // Criar o contêiner
        const container = document.createElement('div');
        container.className = 'container_backdrop'; // Adicione a classe container
  
        // Criar o spinner
        const spinner = document.createElement('div');
        spinner.className = 'spinner';
  
        // Criar o span
        const span = document.createElement('span');
        span.innerText = text;
  
        // Adicionar o spinner e o span ao contêiner
        container.appendChild(spinner);
        container.appendChild(span);
  
        // Adicionar o contêiner ao backdrop
        backdrop.appendChild(container);
        // Adicionar o backdrop ao body
        document.body.appendChild(backdrop);
  
        return backdrop;
      };
    }
  };
  
  export default backdropPlugin;
  